import { lazy } from 'react';
import type { RouteItem } from '../route';

const Home = lazy(() => import('../pages/Home'));
const Blog = lazy(() => import('../pages/Blog'));
const BlogDetail = lazy(() => import('../pages/Blog/Detail'));
const About = lazy(() => import('../pages/About'));
const Download = lazy(() => import('../pages/Download'));
const Contract = lazy(() => import('../pages/Contract'));
const Policy = lazy(() => import('../pages/Policy'));
const Features = lazy(() => import('../pages/Features'));
const Contact = lazy(() => import('../pages/Contact'));
const Page404 = lazy(() => import('../pages/404'));
const Login = lazy(() => import('../pages/Login'));
const Taiji = lazy(() => import('../pages/Taiji'));
const TaijiCloud = lazy(() => import('../pages/TaijiCloud'));

const routes: RouteItem[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/blog',
		element: <Blog />,
	},
	{
    path: '/blog/:id',
		element: <BlogDetail />,
  },
  {
    path: '/about',
    element: <About />,
	},
	{
    path: '/download',
    element: <Download />,
  },
  {
    path: '/contract',
    element: <Contract />,
  },
  {
    path: '/policy',
    element: <Policy />,
  },
  {
    path: '/features',
    element: <Features />,
  },
  {
    path: '/contact',
    element: <Contact />,
	},
	{
    path: '/login',
    element: <Login />,
	},
  {
    path: '/taiji',
    element: <Taiji />
  },
  {
    path: '/tjcloud',
    element: <TaijiCloud />
  },
  {
    path: '*',
    element: <Page404 />,
  },
];

export default routes;
